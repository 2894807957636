import 'animate.css'
import '../../../assets/icon/font-icon.js'
import Header from '../common/Header.vue'
import Footer from '../common/Footer.vue'
import { ElCarousel, ElCarouselItem, ElCard, ElCollapse, ElCollapseItem } from 'element-plus'
export default {
    components: {
        Header: Header,
        Footer: Footer,
        [ElCarousel.name]: ElCarousel,
        [ElCarouselItem.name]: ElCarouselItem,
        [ElCard.name]: ElCard,
        [ElCollapse.name]: ElCollapse,
        [ElCollapseItem.name]: ElCollapseItem
    },
    data () {
        return {
            color_m: '',
            color_s1: '',
            bannerHeight: '0px',
            b1show: false,
            b2show: false,
            esActive: '1',
            psActive: '1',
            gsActive: '1'
        }
    },
    activated () {
        var query = this.$route.query
        alert(query)
    },
    mounted () {
        this.color_m = window.const.global.HRP_COLOR_M
        this.color_s1 = window.const.global.HRP_COLOR_S1
        var width = document.documentElement.clientWidth
        this.bannerHeight = parseInt(820 / 1920 * width) + 'px'
        this.b1show = true
        var query = this.$route.query
        if (query.service !== undefined && query.service !== '') {
            if (query.service === 'enterprise') {
                window.scrollTo({
                    top: 1700,
                    left: 0,
                    behavior: 'smooth'
                })
            } else if (query.service === 'person') {
                window.scrollTo({
                    top: 2300,
                    left: 0,
                    behavior: 'smooth'
                })
            } else if (query.service === 'government') {
                window.scrollTo({
                    top: 2800,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    },
    methods: {
        bannerChange (val) {
            if (val === 0) {
                this.b1show = true
                this.b2show = false
            } else {
                this.b1show = false
                this.b2show = true
            }
        }
    }
}
