<template>
  <div class="container">
    <Header active="home" />
    <div class="main">
      <el-carousel
        class="banner"
        height="500px"
        @change="bannerChange"
        interval="6000"
      >
        <el-carousel-item>
          <el-row class="item01 bg01">
            <el-col :span="12"
              ><transition
                enter-active-class="animate__animated animate__fadeIn cus-animated"
                leave-active-class="animate__animated animate__fadeOut"
              >
                <div v-if="b1show" key="ban01" class="text">
                  <div class="title">数据智能+专家经验+专业服务=正确决策</div>
                  <div class="desc">
                    海量数据使用AI算法进行筛选分析，并通过知名专家在行业领域的资深经验，助您做出正确决策，免走弯路，省时、省钱、省心！
                  </div>
                </div>
              </transition></el-col
            >
            <el-col :span="12">
              <img
                class="img01"
                :src="require('../../../assets/images/website/ban-01.svg')"
              />
            </el-col>
          </el-row>
        </el-carousel-item>
        <el-carousel-item>
          <el-row class="item02 bg02">
            <el-col :span="12"
              ><transition
                enter-active-class="animate__animated animate__fadeIn cus-animated"
                leave-active-class="animate__animated animate__fadeOut"
              >
                <div v-if="b2show" key="ban01" class="text">
                  <div class="title">全网数据实时采集+智能分析</div>
                  <div class="desc">
                    实时对全球超10000+的网站更新的实时数据进行采集，并使用AI模型进行分析，对有价值的信息进行提取并保存入库，为后续服务提供数据支持
                  </div>
                </div>
              </transition></el-col
            >
            <el-col :span="12">
              <img
                class="img02"
                :src="require('../../../assets/images/website/ban-02.svg')"
              />
            </el-col>
          </el-row>
        </el-carousel-item>
        <!-- <el-carousel-item>
          <div class="item">
            <transition
              enter-active-class="animate__animated animate__fadeIn cus-animated"
              leave-active-class="animate__animated animate__fadeOut"
            >
              <div v-if="b2show" key="ban02" class="text">
                <div class="title">智能数据分析&nbsp;&nbsp;精准匹配岗位</div>
                <div class="desc">
                  通过 “AI+大数据”
                  对个人简历及需求进行智能分析,并与工作岗位进行精准匹配,为客户提供智能化精准服务
                </div>
              </div>
            </transition>
            <img
              class="img"
              :src="require('../../../assets/images/website/banner/home-2.jpg')"
            />
          </div>
        </el-carousel-item> -->
      </el-carousel>
      <div class="advantage">
        <div class="title">我们的优势</div>
        <div class="title-en">Our Advantage</div>
        <div class="content">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/1.svg')"
                  />
                </div>
                <div class="name">大数据平台</div>
                <div class="desc">
                  前瞻产业研究院是一家自建产业大数据库平台做研究的咨询机构。前瞻数据库汇集全球各行业商业数据，有多达100万数据指标，超过90多亿条优质数据
                </div></el-card
              >
            </el-col>
            <el-col :span="8"
              ><el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/2.svg')"
                  />
                </div>
                <div class="name">自主研发专利技术</div>
                <div class="desc">
                  是一家拥有国家高新技术资质、自主研发产业大数据并获得“双软”认证及国家版权局、计算机软件著作权大数据专利技术的个人、企业、政府机构等咨询服务机构
                </div></el-card
              >
            </el-col>
            <el-col :span="8">
              <el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/3.svg')"
                  />
                </div>
                <div class="name">全数据支撑</div>
                <div class="desc">
                  得到国家信息中心、统计局、国务院发展中心、商务研究部、海关总署、清华图书馆、国家图书馆、100多行业协会、200多家研究所、1500多种专业刊物等庞大数据支持
                </div></el-card
              ></el-col
            >
            <el-col :span="8">
              <el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/4.svg')"
                  />
                </div>
                <div class="name">信息收集检测体系</div>
                <div class="desc">
                  连续1多年对主要产业建立每周、每月、每年的持续信息收集检测体系。前瞻全面建立上千细分产业市场数据库并持续更新
                </div></el-card
              >
            </el-col>
            <el-col :span="8">
              <el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/5.svg')"
                  />
                </div>
                <div class="name">高准确细分数据</div>
                <div class="desc">
                  设立了高端访谈部门，专门针对政府部门、行业专家、主管单位等进行实地访谈。对于那些非上市公司的行业有持续多年市场监控与跟踪调研优势
                </div></el-card
              >
            </el-col>
            <el-col :span="8">
              <el-card class="item">
                <div>
                  <img
                    class="img"
                    :src="require('../../../assets/images/website/icon/6.svg')"
                  />
                </div>
                <div class="name">数据模型与创新技术</div>
                <div class="desc">
                  采用与国际同步SCP科学分析模型，及产业链研究地图指引。采用ETL数据抽取转化加载工具，及网络爬虫技术，专业数据建模
                </div></el-card
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="stats">
        <el-row :gutter="30">
          <el-col :span="6">
            <div class="item">
              <div class="icon">
                <img
                  class="img"
                  :src="require('../../../assets/images/website/counter/1.svg')"
                />
              </div>
              <div class="name">政务公开数据</div>
              <div class="number">30.5GB+</div>
            </div>
          </el-col>
          <el-col :span="6"
            ><div class="item">
              <div class="icon">
                <img
                  class="img"
                  :src="require('../../../assets/images/website/counter/2.svg')"
                />
              </div>
              <div class="name">互联网公开数据</div>
              <div class="number">650.3GB+</div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="item">
              <div class="icon">
                <img
                  class="img"
                  :src="require('../../../assets/images/website/counter/3.svg')"
                />
              </div>
              <div class="name">全球企业数据</div>
              <div class="number">1.2亿条+</div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="item">
              <div class="icon">
                <img
                  class="img"
                  :src="require('../../../assets/images/website/counter/4.svg')"
                />
              </div>
              <div class="name">模型分析预测</div>
              <div class="number">13.2GB+</div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="service">
        <div class="title">我们能提供的服务</div>
        <div class="content">
          <el-row id="enterprise">
            <el-col :span="14" class="item">
              <div class="name">企业服务与咨询</div>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 市场进入咨询
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 投资决策咨询
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 商业模式咨询
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 市场调研服务
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 人力培训及猎头
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 产业和技术升级
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 金融财务服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 企业投融资和并购
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl brd">
                    <i class="el-icon-success icon"></i>
                    股权和组织架构及商业模型
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl">
                    <i class="el-icon-success icon"></i>
                    企业增长及专精特伴随服务
                  </div></el-col
                >
              </el-row>
              <!-- <el-collapse v-model="esActive" accordion>
                <el-collapse-item name="1">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 市场进入咨询 </span>
                  </template>
                  <div class="dtl">
                    帮助客户在新的或现有的市场中寻找和抓住机会，增加收入和利润，提高竞争力和品牌知名度
                  </div>
                </el-collapse-item>
                <el-collapse-item name="2">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 投资决策咨询 </span>
                  </template>
                  <div class="dtl">
                    对投资方案进行研究论证，帮助企业或决策者了解投资项目或产品所属的行业市场的发展现状及未来趋势，能够准确的判断投资方向
                  </div>
                </el-collapse-item>
                <el-collapse-item name="3">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 商业模式咨询 </span>
                  </template>
                  <div class="dtl">
                    对商业环境、市场需求、技术趋势等充分把握的基础上，精确定位目标客户，设计业务系统，构建关键能力，进行盈利结构、现金流结构设计，并通过验证、推广、规模化三个阶段，形成企业价值
                  </div>
                </el-collapse-item>
                <el-collapse-item name="4">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 市场调研服务 </span>
                  </template>
                  <div class="dtl">
                    了解市场需求、竞争对手、消费者行为等信息，为企业的决策提供有力的支持
                  </div>
                </el-collapse-item>
                <el-collapse-item name="5">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 人力培训及猎头 </span>
                  </template>
                  <div class="dtl">
                    通过企业自身内部结构、人才的需求变化及猎头行动等方面分析企业人才流失的原因，从而制定猎头和反猎头策略，让企业更好的管理人才
                  </div>
                </el-collapse-item>
                <el-collapse-item name="6">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 产业和技术升级 </span>
                  </template>
                  <div class="dtl">
                    通过数据分析对产业进行优化，使用IOT+5G+AI等技术对传统的的产业的技术进行升级改造，提高生产效率及利润率
                  </div>
                </el-collapse-item>
                <el-collapse-item name="7">
                  <template #title>
                    <i class="el-icon-success icon"></i>
                    <span class="tl"> 金融财务服务 </span>
                  </template>
                  <div class="dtl">
                    提升财务管理能力、降低财务成本、融资安排等提供咨询，在资金的筹集、运用、投资、分配等方面为决策者提供有价值的建议，帮助企业实现财务管理任务，扩大理财收益
                  </div>
                </el-collapse-item>
              </el-collapse> -->
            </el-col>
            <el-col :span="10">
              <div class="img">
                <img
                  class="bg"
                  :src="
                    require('../../../assets/images/website/service/1-of-1.svg')
                  "
                /><img
                  class="ic"
                  :src="require('../../../assets/images/website/service/1.svg')"
                />
              </div>
            </el-col>
          </el-row>
          <el-row id="person" class="btd">
            <el-col :span="10">
              <div class="img">
                <img
                  class="bg"
                  :src="
                    require('../../../assets/images/website/service/2-of-2.svg')
                  "
                /><img
                  class="ic"
                  :src="require('../../../assets/images/website/service/2.svg')"
                />
              </div>
            </el-col>
            <el-col :span="14" class="item">
              <div class="name">个人服务与咨询</div>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 创业服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 成果转化服务
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 职称评定服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 课题申报服务
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 书籍发行服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 家族发展咨询规划
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 个人财务金融服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 个人法务服务
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl brd">
                    <i class="el-icon-success icon"></i>
                    教育咨询服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl">
                    <i class="el-icon-success icon"></i>
                    风险规避咨询服务
                  </div></el-col
                >
              </el-row>
            </el-col>
          </el-row>
          <el-row id="goverment" class="btd">
            <el-col :span="14" class="item">
              <div class="name">政府及组织服务与咨询</div>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 招商引资服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 产业规划调研
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl bbd brd">
                    <i class="el-icon-success icon"></i> 公关服务
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl bbd">
                    <i class="el-icon-success icon"></i> 人才引进服务
                  </div></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="12"
                  ><div class="tl brd">
                    <i class="el-icon-success icon"></i> GDP与就业增长
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="tl">
                    <i class="el-icon-success icon"></i> 影响力与吸引力塑造传播
                  </div></el-col
                >
              </el-row>
            </el-col>
            <el-col :span="10">
              <div class="img">
                <img
                  class="bg"
                  :src="
                    require('../../../assets/images/website/service/4-of-4.svg')
                  "
                /><img
                  class="ic"
                  :src="require('../../../assets/images/website/service/4.svg')"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="scss" scoped>
@import './Index.scss';
</style>
